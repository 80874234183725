// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import logo from "../images/logoDark.png";

class Movies extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Movie Making Tutor";
  };

  openingParagraph = (
    <div>
      <span>Movie making is one of the easiest and most fun ways for children to use technology educationally and creatively.  When making a movie, kids must think about the script, the visuals, the performance, and the many technical processes involved in editing and fine tuning a final product.  They also must learn to work with a team, where different people take on different roles.</span>
      <div style={{ height: "10px" }} />
      <span>Below are some movies I've made with my students.</span>
    </div>
  );

  eggIntro = (
    <div>
      <span>Can these kids save their school from the evil Bird Beast?  Watch to find out!</span>
    </div>
  );

  eggVideo = (
    <div style={{ textAlign: "center" }}>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/PnuZyzwhUb4"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  carboIntro = (
    <div>
      <span>What happens when a couple kids find a portal behind the copy machine?  Watch to find out!</span>
    </div>
  );

  carboVideo = (
    <div style={{ textAlign: "center" }}>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/FjdINqM6qcc"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please
      send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc">zach@codingtutor.nyc</a>.<br />
    </div>
  );

  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "34pt" }}>Movie Making</h1>
          <h2 style={{ fontSize: "17pt", paddingTop: "5px" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">The Evil Egg</div>
          {this.eggIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.eggVideo}
          </div>

          <div className="subHeadingMobileScratch">The Carboniferous Classroom</div>
          {this.carboIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.carboVideo}
          </div>

          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "110px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "50pt" }}>Movie Making</h1>
          <h2 style={{ fontSize: "20pt" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">The Evil Egg</div>
          {this.eggIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.eggVideo}
          </div>

          <div className="subHeadingDesktopScratch">The Carboniferous Classroom</div>
          {this.carboIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.carboVideo}
          </div>

          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "120px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default Movies;
