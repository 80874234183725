// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import logo from "../images/logoDark.png";


class ScratchJr extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Scratch Jr. Tutor";

  }

  openingParagraph = (
    <div>
      <span>
        Scratch Jr. is an introductory programming language.  Children ages 5 to 7 use it to code interactive stories, games, and animations by snapping together colorful programming blocks.  Within a few minutes, children can make characters move, spin, change size, disappear, and react to one another.  They can add their own voices and sounds, change backgrounds, and insert images of themselves.
      </span>
      <div style={{ height: "10px" }} />
      <span>
        Scratch Jr. helps young children think logically and sequentially, while also providing them opportunities to be creative and have fun! Below you'll find some videos of Scratch Jr. being taught to young children.
      </span>
    </div>
  );

  boxIntro = (
    <div>
      <span>
        In this lesson—taught live to an entire 1st grade class—children are learning to make characters move around the edge of a box.
      </span>
    </div>
  );

  boxVideo = (
    <div style={{textAlign: "center"}}>
      
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/dCPSzkhcK7E" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

    </div>
  );

  raceIntro = (
    <div>
      <span>
        In this lesson, children learn how to make three characters move at different speeds.
      </span>
    </div>
  );

  raceVideo = (
    <div style={{textAlign: "center"}}>
      
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/KNOEEAXqac8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

    </div>
  );

  

  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc"> 
        zach@codingtutor.nyc
      </a>.<br/>
    </div>
  );


  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "46pt" }}>Scratch Jr.</h1>
          <h2 style={{ fontSize: "18pt", paddingTop: "5px" }}>
          <IonRouterLink href="/" >NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">Moving Around a Box</div>
          {this.boxIntro}

          <div style={{ width: "100%", margin: "auto", marginTop:"18px" }}>
            {this.boxVideo}
          </div>

          <div className="subHeadingMobileScratch">Three-Way Race</div>
          {this.raceIntro}

          <div style={{ width: "100%", margin: "auto", marginTop:"18px" }}>
            {this.raceVideo}
          </div>

          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
            <img style={{ width: "110px" }} src={logo} />
            <br />
            <span style={{ fontSize: "12pt", letterSpacing: "1.4px", textDecoration: "none" }}>
              &copy; CodingTutor.nyc
            </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "50pt" }}>Scratch Jr.</h1>
          <h2 style={{ fontSize: "22pt"}}><IonRouterLink href="/" >NYC Coding & Tech Tutor</IonRouterLink></h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">Moving Around a Box</div>
          {this.boxIntro}

          <div style={{ width: "70%", margin: "auto", marginTop:"18px" }}>
            {this.boxVideo}
          </div>

          <div className="subHeadingDesktopScratch">Three-Way Race</div>
          {this.raceIntro}

          <div style={{ width: "70%", margin: "auto", marginTop:"18px" }}>
            {this.raceVideo}
          </div>

          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
            <img style={{ width: "120px"}} src={logo} />
            <br />
            <span style={{ fontSize: "12pt", letterSpacing: "1.4px", textDecoration: "none" }}>
              &copy; CodingTutor.nyc
            </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default ScratchJr;
