// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import scratch1 from "../images/scratchGif1.gif";
import logo from "../images/logoDark.png";

class Scratch extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Scratch Coding Tutor";

  }

  openingParagraph = (
    <div>
      <span>
        Scratch is a visual programming language designed and maintained by the
        MIT Media Lab. Instead of typing text-based code, Scratch coders create programs
        graphically by stacking and arranging colorful blocks. Scratch can be
        used by children as young as eight to create simple programs, or by
        teens and adults to create complex programs. As of January 2021, almost
        38 million people build and share Scratch projects on a monthly basis.
      </span>
      <div style={{ height: "10px" }} />
      <span>
        Scratch offers an outstanding way for new programmers to learn computer
        science concepts. It's also a great way to have fun and express your creativity!
      </span>
      <div style={{ height: "10px" }} />
      <span>
        Below you'll find tutorials for some projects that my students have coded.
      </span>
    </div>
  );

  fortuneTellerIntro = (
    <div>
      <span>
        This is an easy first project for Scratch newcomers. The user will be
        able to click the green flag button in order to spin an arrow. When the
        arrow stops spinning, a fortune is shown. The fortune displayed
        depends on the color that the arrow is touching after it spins.
      </span>
    </div>
  );

  fortuneTellerVideo = (
    <div style={{textAlign: "center"}}>
    <iframe
      width="100%"
      height="315"
      src="https://www.youtube.com/embed/sdtixA_VBNw"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
    <span><a style={{fontSize: "14pt"}} href="https://scratch.mit.edu/projects/481485898/">View Completed Project<br/>(Scratch website)</a></span>
    </div>
  );

  mazeGameIntro = (
    <div>
      <span>
        This is a slightly more advanced Scratch project.  In this game, the user will be able to move a character around using the keyboard arrow keys.  Bad guys will glide back and forth between coordinates.  And a key will unlock a door!  Can you survive?
      </span>
    </div>
  );

  mazeGameVideo = (
    <div style={{textAlign: "center"}}>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/2KovNOvR-zY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      <span><a style={{fontSize: "14pt"}} href="https://scratch.mit.edu/projects/481506468/">View Completed Project<br/>(Scratch website)</a></span>
    </div>
  );

  subGameIntro = (
    <div>
      <span>
      This Scratch project is suited for people who are no longer beginners.  In this game, the user will control a submarine using only the space bar.  Gems, rocks, and bombs will fly towards the submarine at an increasing rate of speed.  The goal of the game is to survive as long as possible, collecting as many gems as possible.
      </span>
    </div>
  );

  subGameVideo = (
    <div style={{textAlign: "center"}}>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/kYTyeQ3nZog" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

      <span><a style={{fontSize: "14pt"}} href="https://scratch.mit.edu/projects/484770666/">View Completed Project<br/>(Scratch website)</a></span>
    </div>
  );

  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc"> 
        zach@codingtutor.nyc
      </a>.
      <br/>
    </div>
  );


  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "36pt" }}>Scratch Coding</h1>
          <h2 style={{ fontSize: "18pt", paddingTop: "5px" }}>
          <IonRouterLink href="/" >NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          <img src={scratch1} className="gifMobile" />
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">Fortune Teller</div>
          {this.fortuneTellerIntro}

          <div style={{ width: "100%", margin: "auto", marginTop:"18px" }}>
            {this.fortuneTellerVideo}
          </div>

          <div className="subHeadingMobileScratch">Maze Game</div>
          {this.mazeGameIntro}

          <div style={{ width: "100%", margin: "auto", marginTop:"18px" }}>
            {this.mazeGameVideo}
          </div>

          <div className="subHeadingMobileScratch">Submarine Game</div>
          {this.subGameIntro}

          <div style={{ width: "100%", margin: "auto", marginTop:"18px" }}>
            {this.subGameVideo}
          </div>

          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
            <img style={{ width: "110px" }} src={logo} />
            <br />
            <span style={{ fontSize: "12pt", letterSpacing: "1.4px", textDecoration: "none" }}>
              &copy; CodingTutor.nyc
            </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "50pt" }}>Scratch Coding</h1>
          <h2 style={{ fontSize: "22pt"}}><IonRouterLink href="/" >NYC Coding & Tech Tutor</IonRouterLink></h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          <img src={scratch1} className="gifDesktop" />
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">Fortune Teller</div>
          {this.fortuneTellerIntro}

          <div style={{ width: "70%", margin: "auto", marginTop:"18px" }}>
            {this.fortuneTellerVideo}
          </div>

          <div className="subHeadingDesktopScratch">Maze Game</div>
          {this.mazeGameIntro}

          <div style={{ width: "70%", margin: "auto", marginTop:"18px" }}>
            {this.mazeGameVideo}
          </div>

          <div className="subHeadingDesktopScratch">Submarine Game</div>
          {this.subGameIntro}

          <div style={{ width: "70%", margin: "auto", marginTop:"18px" }}>
            {this.subGameVideo}
          </div>

          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
            <img style={{ width: "120px" }} src={logo} />
            <br />
            <span style={{ fontSize: "12pt", letterSpacing: "1.4px", textDecoration: "none" }}>
              &copy; CodingTutor.nyc
            </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default Scratch;
