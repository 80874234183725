// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import logo from "../images/logoDark.png";

class Lego extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Lego Robotics Tutor";
  };

  openingParagraph = (
    <div>
      <span>Building and programming a Lego robot requires children to think creatively and logically in both a physical and digital sense.  They must build physical objects that can be controlled through code.  This makes building robots both extra exciting and extra challenging.
      </span>
      <div style={{ height: "10px" }} />
      <span>Their are two main Lego robotics platforms.  <strong>Lego Wedo</strong> is best for ages five to nine.  <strong>Lego Mindstorms</strong> is best for ages ten and up.</span>
    </div>
  );

  legoIntro = (
    <div>
      <span>In this video, a third and fourth grader explain how they built and programmed a simple catapult using a Lego Wedo kit.</span>
    </div>
  );

  legoVideo = (
    <div style={{ textAlign: "center" }}>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/30zfj_Q7zAs"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please
      send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc">zach@codingtutor.nyc</a>.<br />
    </div>
  );

  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "34pt" }}>Lego Robotics</h1>
          <h2 style={{ fontSize: "16pt", paddingTop: "5px" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">Catapult</div>
          {this.legoIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.legoVideo}
          </div>

          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "110px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "50pt" }}>Lego Robotics</h1>
          <h2 style={{ fontSize: "22pt" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">Catapult</div>
          {this.legoIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.legoVideo}
          </div>

          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "120px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default Lego;
