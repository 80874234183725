// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import logo from "../images/logoDark.png";
import p1 from "../images/photos/1.jpg";
import p2 from "../images/photos/2.jpg";
import p3 from "../images/photos/3.jpg";
import p4 from "../images/photos/4.jpg";
import p5 from "../images/photos/5.jpg";
import p6 from "../images/photos/6.jpg";
import p7 from "../images/photos/7.jpg";
import p8 from "../images/photos/8.jpg";
import p9 from "../images/photos/9.jpg";
import p10 from "../images/photos/10.jpg";
import p11 from "../images/photos/11.jpg";
import p12 from "../images/photos/12.jpg";
import p13 from "../images/photos/13.jpg";
import p14 from "../images/photos/14.jpg";
import p15 from "../images/photos/15.jpg";
import p16 from "../images/photos/16.jpg";
import p17 from "../images/photos/17.jpg";
import p18 from "../images/photos/18.jpg";
import p19 from "../images/photos/19.jpg";
import p20 from "../images/photos/20.jpg";
import p22 from "../images/photos/22.jpg";
import p23 from "../images/photos/23.jpg";
import p24 from "../images/photos/24.jpg";
import p25 from "../images/photos/25.jpg";
import p26 from "../images/photos/26.jpg";
import p27 from "../images/photos/27.jpg";
import p28 from "../images/photos/28.jpg";
import p29 from "../images/photos/29.jpg";
import p30 from "../images/photos/30.jpg";
import p31 from "../images/photos/31.jpg";
import p32 from "../images/photos/32.jpg";
import p33 from "../images/photos/33.jpg";
import p34 from "../images/photos/34.jpg";


class Photography extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Photography Tutor | Photographer";
  };

  openingParagraph = (
    <div>
      <span>I've been shooting dSLR photography for over a decade.  I've shot many events, including weddings.  In just a few lessons, I can show you how to use your camera like a pro.  And I'll teach you some easy editing tricks, which can dramatically change the appearance of your photos.</span>
      <div style={{ height: "10px" }} />
      <span>I'm also available for hire as a photographer of portraits, families, children, events, and more.  Get in touch!</span>
    </div>
  );

    photo1 = (<img src={p1} />);
    photo2 = (<img src={p2} />);
    photo3 = (<img src={p3} />);
    photo4 = (<img src={p4} />);
    photo5 = (<img src={p5} />);
    photo6 = (<img src={p6} />);
    photo7 = (<img src={p7} />);
    photo8 = (<img src={p8} />);
    photo9 = (<img src={p9} />);
    photo10 = (<img src={p10} />);
    photo11 = (<img src={p11} />);
    photo12 = (<img src={p12} />);
    photo13 = (<img src={p13} />);
    photo14 = (<img src={p14} />);
    photo15 = (<img src={p15} />);
    photo16 = (<img src={p16} />);
    photo17 = (<img src={p17} />);
    photo18 = (<img src={p18} />);
    photo19 = (<img src={p19} />);
    photo20 = (<img src={p20} />);
    photo22 = (<img src={p22} />);
    photo23 = (<img src={p23} />);
    photo24 = (<img src={p24} />);
    photo25 = (<img src={p25} />);
    photo26 = (<img src={p26} />);
    photo27 = (<img src={p27} />);
    photo28 = (<img src={p28} />);
    photo29 = (<img src={p29} />);
    photo30 = (<img src={p30} />);
    photo31 = (<img src={p31} />);
    photo32 = (<img src={p32} />);
    photo33 = (<img src={p33} />);
    photo34 = (<img src={p34} />);


  contact = (
    <div>
      To learn more about my tutoring or photography services, please
      send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc">zach@codingtutor.nyc</a>.<br />
    </div>
  );

  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "40pt" }}>Photography</h1>
          <h2 style={{ fontSize: "18pt", paddingTop: "5px" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">Select Photos</div>
          
          {this.photo24}<br/>
          {this.photo12}<br/>
          {this.photo31}<br/>
          {this.photo13}<br/>
          {this.photo15}<br/>
          {this.photo16}<br/>
          {this.photo17}<br/>
          {this.photo18}<br/>
          {this.photo19}<br/>
          {this.photo20}<br/>
          {this.photo22}<br/>
          {this.photo23}<br/>
          {this.photo25}<br/>
          {this.photo26}<br/>
          {this.photo27}<br/>
          {this.photo28}<br/>
          {this.photo29}<br/>
          {this.photo30}<br/>
          {this.photo32}<br/>
          {this.photo33}<br/>
          {this.photo34}<br/>
          {this.photo1}<br/>
          {this.photo2}<br/>
          {this.photo3}<br/>
          {this.photo4}<br/>
          {this.photo5}<br/>
          {this.photo6}<br/>
          {this.photo7}<br/>
          {this.photo8}<br/>
          {this.photo9}<br/>
          {this.photo10}<br/>
          {this.photo11}<br/>


          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "110px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "56pt" }}>Photography</h1>
          <h2 style={{ fontSize: "22pt" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">Select Photos</div>
          <div style={{maxWidth: "430px", margin: "auto"}}>

          {this.photo24}<br/>
          {this.photo12}<br/>
          {this.photo31}<br/>
          {this.photo13}<br/>
          {this.photo15}<br/>
          {this.photo16}<br/>
          {this.photo17}<br/>
          {this.photo18}<br/>
          {this.photo19}<br/>
          {this.photo20}<br/>
          {this.photo22}<br/>
          {this.photo23}<br/>
          {this.photo25}<br/>
          {this.photo26}<br/>
          {this.photo27}<br/>
          {this.photo28}<br/>
          {this.photo29}<br/>
          {this.photo30}<br/>
          {this.photo32}<br/>
          {this.photo33}<br/>
          {this.photo34}<br/>
          {this.photo1}<br/>
          {this.photo2}<br/>
          {this.photo3}<br/>
          {this.photo4}<br/>
          {this.photo5}<br/>
          {this.photo6}<br/>
          {this.photo7}<br/>
          {this.photo8}<br/>
          {this.photo9}<br/>
          {this.photo10}<br/>
          {this.photo11}<br/>

          </div>

          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "120px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default Photography;
