// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import logo from "../images/logoDark.png";

class Design extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "3D Design Tutor";
  };

  openingParagraph = (
    <div>
      <span>
        When children create 3D designs, they're developing both creative and
        technical skills. If they have access to a 3D printer, they can turn
        their designs into physical objects. They can also import their designs
        into other digital projects. Or they can just enjoy creating something
        that looks cool!
      </span>
      <div style={{ height: "10px" }} />
      <span>
        For young kids, <strong>TinkerCad.com</strong> is the best 3D design
        tool. Older kids often enjoy using professional tools such as{" "}
        <strong>Blender</strong> or the <strong>Unreal Engine</strong>.
      </span>
    </div>
  );

  bumpIntro = (
    <div>
      <span>
        A ziggurat is a massive structure built in ancient Mesopotamia. It’s a
        terraced compound of successively receding levels. Ziggurats were built
        by the ancient Sumerians, Akkadians, Assyrians, Elamites, Eblaites and
        Babylonians, most likely for religious purposes.
      </span>
      <div style={{ height: "10px" }} />
      <span>
        These 5th graders designed their Ziggurats using TinkerCad.com, a
        web-based 3D modelling tool.
      </span>
    </div>
  );

  bumpVideo = (
    <div style={{ textAlign: "center" }}>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/7qs6GTfQSEI"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please
      send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc">zach@codingtutor.nyc</a>.<br />
    </div>
  );

  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "42pt" }}>3D Design</h1>
          <h2 style={{ fontSize: "17pt", paddingTop: "5px" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">Ziggurats</div>
          {this.bumpIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.bumpVideo}
          </div>

          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "110px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "58pt" }}>3D Design</h1>
          <h2 style={{ fontSize: "20pt" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">Ziggurats</div>
          {this.bumpIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.bumpVideo}
          </div>

          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "120px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default Design;
