import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import Scratch from "./pages/Scratch";
import ScratchJr from "./pages/ScratchJr";
import Lego from "./pages/Lego";
import Animation from "./pages/Animation";
import Design from "./pages/Design";
import Movies from "./pages/Movies";
import Claymation from "./pages/Claymation";
import JavaScript from "./pages/JavaScript";
import Photography from "./pages/Photography";
import VideoEditing from "./pages/VideoEditing";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/main.css";

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      size: ""
    };
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);
    if (window.innerWidth >= 720){
      this.setState({
        size: "desktop"
      })
    } else {
      this.setState({
        size: "mobile"
      })
    }
    
  };

  handleResize = (e: any) => {
    const w = window.innerWidth;
    // console.log(w)
    // console.log(e)

    if (this.state.size == "desktop" && w < 720) {
      this.setState({
        size: "mobile"
      })
    }
    if (this.state.size == "mobile" && w >= 720) {
      this.setState({
        size: "desktop"
      })
    }
  }

  render() {
    // console.log(this.state.size)

    // var content: any;
    // if (this.state.size == "desktop"){
    //   content = "DESKTOP"
    // }
    // if (this.state.size == "mobile"){
    //   content = "MOBILE"
    // }
    // console.log(content)

    return (
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
          <Route path="/" exact={true}>
            <Home appState={this.state} />
          </Route>

          <Route path="/scratch" exact={true}>
            <Scratch appState={this.state} />
          </Route>

          <Route path="/scratchjr" exact={true}>
            <ScratchJr appState={this.state} />
          </Route>

          <Route path="/lego" exact={true}>
            <Lego appState={this.state} />
          </Route>

          <Route path="/animation" exact={true}>
            <Animation appState={this.state} />
          </Route>

          <Route path="/3d-design" exact={true}>
            <Design appState={this.state} />
          </Route>

          <Route path="/movies" exact={true}>
            <Movies appState={this.state} />
          </Route>

          <Route path="/claymation" exact={true}>
            <Claymation appState={this.state} />
          </Route>

          <Route path="/javascript" exact={true}>
            <JavaScript appState={this.state} />
          </Route>

          <Route path="/photography" exact={true}>
            <Photography appState={this.state} />
          </Route>

          <Route path="/editing" exact={true}>
            <VideoEditing appState={this.state} />
          </Route>



            {/* <Route path="/" component={Home} exact={true} /> */}
            {/* <Route exact path="/" render={() => <Redirect to="/home" />} /> */}
            
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    );
  }
}

export default App;
