// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import zach from "../images/zach.jpg";
import logo from "../images/logoDark.png";
import logoTop from "../images/logoTop.png";

class Home extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  openingParagraph = (
    <span>
      Welcome! My name is Zach, and I'm a full-time technology teacher at a
      private <a href="https://www.corlearsschool.org">elementary school</a> in
      Manhattan. In addition to my regular job, I offer tutoring in various
      technology-related subjects to people of all ages. I work one-on-one or
      with small groups, and my services are available in New York City or
      remotely via video conference.
    </span>
  );

  credentials = (
    <div>
      <li>
        Master's degree from Columbia University in K-12 Technology Education
      </li>
      <li>Bachlor's degree from Connecticut College in English</li>
      <li>NYS Professional Certificate: Educational Technology Specialist</li>
      <li>NYS Professional Certificate: Childhood Education</li>
      
      <li>
        Author of{" "}
        <a href="https://www.amazon.com/Math-Art-Hands-Activities-Grades/dp/1453636439">
          Math Art: Hands-On Math Activities for Grades of 2-4
        </a>
        
      </li>
      <li>
        Developer of the{" "}
        <a href="https://www.coolneighbors.com/">Cool Neighbors</a> website, iOS
        app, and Android app
      </li>
      <li>
        Over twelve years of teaching experience (elementary school, high
        school, & adults)
      </li>
    </div>
  );
  teenAdults = (
    <div>
      <li>
        Coding websites &{" "}
        <IonRouterLink href="/javascript">web apps</IonRouterLink> using
        Javascript
      </li>
      <li>
        Coding <IonRouterLink href="/javascript">iOS</IonRouterLink> &{" "}
        <IonRouterLink href="/javascript">Android apps</IonRouterLink> using
        Javascript
      </li>
      <li>
        Coding with{" "}
        <IonRouterLink href="/javascript">React + Ionic</IonRouterLink>
      </li>
      <li>
        <IonRouterLink href="/photography">Photography</IonRouterLink> & photo
        editing
      </li>
      <li>
        <IonRouterLink href="/editing">Video editing</IonRouterLink> and audio
        editing
      </li>
      <li>Game design (Unreal Engine)</li>
      <li>Operating system fundamentals (Windows, Mac, Chromebook, & mobile)</li>
      <li>All other hardware and software troubleshooting</li>
    </div>
  );

  kids = (
    <div>
      <li>
        Coding with <IonRouterLink href="/scratch">Scratch</IonRouterLink> (ages
        8-14)
      </li>
      <li>
        Coding with <IonRouterLink href="/scratchjr">Scratch Jr</IonRouterLink>.
        (ages 5-8)
      </li>
      <li>
        <IonRouterLink href="/lego">Lego robotics</IonRouterLink>
      </li>
      <li>
        <IonRouterLink href="/animation">Animation</IonRouterLink>
      </li>
      <li>
        <IonRouterLink href="/3d-design">3D design</IonRouterLink>
      </li>
      <li>
        <IonRouterLink href="/movies">Movie making</IonRouterLink> and video
        editing
      </li>
      <li>
        Stop motion and{" "}
        <IonRouterLink href="/claymation">claymation</IonRouterLink>
      </li>
      <li>Digital drawing</li>
      <li>Minecraft and Roblox</li>
    </div>
  );

  // applications = (
  //   <div>
  //     <li>iMovie</li>
  //     <li>Garage Band</li>
  //     <li>Adobe Photoshop</li>
  //     <li>Adobe Illustrator</li>
  //     <li>Adobe Premier Pro</li>
  //     <li>Adobe Creative Suite</li>
  //     <li>MS Office</li>
  //     <li>Most other Windows, Apple, & Google applications</li>
  //   </div>
  // );

  prices = (
    <div>
      <li>
        <span style={{ display: "inline-block", width: "144px" }}>
          One-on-one:
        </span>
        <strong>$140/hour</strong>
      </li>
      <li>
        <span style={{ display: "inline-block", width: "144px" }}>
          Class of two:
        </span>
        <strong>$90/hour</strong>
      </li>
      <li>
        <span style={{ display: "inline-block", width: "144px" }}>
          Class of three:
        </span>
        <strong>$70/hour</strong>
      </li>
      <li>
        <span style={{ display: "inline-block", width: "144px" }}>
          Class of four:
        </span>
        <strong>$60/hour</strong>
      </li>
      <br />
      <div style={{ fontStyle: "italic" }}>Your first class is half price!</div>
    </div>
  );

  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please
      send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc">zach@codingtutor.nyc</a>.
      <br />
      <br />
      I look forward to hearing from you! <br />
    </div>
  );

  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <div style={{ fontSize: "29pt" }}>Zachary J. Brewer</div>
          <h1 style={{ fontSize: "18pt", paddingTop: "5px" }}>
            NYC Coding & Tech Tutor
          </h1>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          <img src={zach} className="headshotMobile" />
          {this.openingParagraph}

          <div className="subHeadingMobile">Credentials</div>
          <ul style={{ marginTop: "8px", paddingLeft: "30px" }}>
            {this.credentials}
          </ul>

          <div className="subHeadingMobile">Subjects for Kids</div>
          <ul style={{ marginTop: "8px", paddingLeft: "30px" }}>{this.kids}</ul>

          <div className="subHeadingMobile">Subjects for Adults & Teens</div>
          <ul style={{ marginTop: "8px", paddingLeft: "30px" }}>
            {this.teenAdults}
          </ul>

          {/* <div className="subHeadingMobile">Applications</div>
          <ul style={{ marginTop: "8px", paddingLeft: "30px" }}>
            {this.applications}
          </ul> */}

          <div className="subHeadingMobile">Prices</div>
          <ul
            style={{
              marginTop: "8px",
              paddingLeft: "30px",
              paddingRight: "0px",
              marginBottom: "0px",
            }}
          >
            {this.prices}
          </ul>
          <div className="subHeadingMobile">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <a href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "110px" }} src={logo} />
              <br />
              <span style={{ fontSize: "12pt", letterSpacing: "1.4px" }}>
                &copy; CodingTutor.nyc
              </span>
            </a>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <div style={{ fontSize: "50pt" }}>Zachary J. Brewer</div>
          {/* <img className="logoTop" src={logoTop} /><br/> */}
          <h1 className="homeHeading" style={{ fontSize: "30pt" }}>
            NYC Coding & Tech Tutor
          </h1>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          <img src={zach} className="headshotDesktop" />
          {this.openingParagraph}

          <div className="subHeadingDesktop">Credentials</div>
          <ul style={{ marginTop: "8px", paddingLeft: "44px" }}>
            {this.credentials}
          </ul>

          <div className="subHeadingDesktop">Subjects for Kids</div>
          <ul style={{ marginTop: "8px", paddingLeft: "44px" }}>{this.kids}</ul>

          <div className="subHeadingDesktop">Subjects for Adults & Teens</div>
          <ul style={{ marginTop: "8px", paddingLeft: "44px" }}>
            {this.teenAdults}
          </ul>

          {/* <div className="subHeadingDesktop">Applications</div>
          <ul style={{ marginTop: "8px", paddingLeft: "44px" }}>
            {this.applications}
          </ul> */}

          <div className="subHeadingDesktop">Prices</div>
          <ul
            style={{
              marginTop: "8px",
              paddingRight: "0px",
              paddingLeft: "44px",
              marginBottom: "0px",
            }}
          >
            {this.prices}
          </ul>
          <div className="subHeadingDesktop">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <a href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "120px" }} src={logo} />
              <br />
              <span style={{ fontSize: "12pt", letterSpacing: "1.4px" }}>
                &copy; CodingTutor.nyc
              </span>
            </a>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default Home;
