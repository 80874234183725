// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent,IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import logo from "../images/logoDark.png";

class JavaScript extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "JavaScript Tutor";
  };

  openingParagraph = (
    <div>
      <span>
        I specialize in teaching JavaScript and the frameworks and libraries
        that are built around JavaScript. Currently, my favorite JavaScript
        frameworks are React, Ionic, and A-Frame (a web-based virtual reality
        framework).
      </span>
      <div style={{ height: "10px" }} />
      <span>
        JavaScript's widespread use makes it arguably the best programming
        language for beginners to learn, especially beginners wishing to
        maximize the types of projects they can build. JavaScript has been the
        primary language of the web for well over a decade. And it is
        increasingly being used to create mobile and desktop applications.
      </span>
      <div style={{ height: "10px" }} />
      <span>
        Below is a video series explaining how JavaScript frameworks can be used
        to build iOS, Android apps, and more!{" "}
        <strong>
          The below series is not for people who are new to programming. Those
          who are new should contact me about learning JavaScript fundamentals
          (or what is often called "vanilla" JavaScript).
        </strong>
      </span>
    </div>
  );

  seriesIntro = (
    <div>
      <span>
        If you know JavaScript, you don't need to learn a new language in order
        to make iOS, Android, and web apps. Instead, you simply need a
        JavaScript framework that can convert your JavaScript code into iOS and
        Android code.
      </span>
      <div style={{ height: "10px" }} />
      <span>Ionic + React is the best framework for this need.</span>
      <div style={{ height: "10px" }} />
      <span>
        This video series will show you how to use the Ionic + React framework
        to build a To-Do List app. The app will feature a sign-up form, a
        sign-in form, and full database functionality (create / read / update /
        delete).
      </span>
      <div style={{ height: "10px" }} />
      <span>
        I will also explain how to use Google’s Firebase to handle the app's
        backend (specifically: user authentication, the database, and web
        hosting).
      </span>
      <div style={{ height: "10px" }} />
      <span>
        Finally, I will show you how to publish the finished app on Apple’s App
        Store and on Google’s Play Store!
      </span>
    </div>
  );

  video1 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px" }}>
        <strong>1. Intro</strong>
      </div>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/iNz2FhxU-EU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  video2 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>2. Environoment Setup</strong>
      </div>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/BJHnazb_7Qw"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  video3 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>3. Version Control</strong>
      </div>
      <iframe
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/9Q-nWu3QXzQ"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );

  video4 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>4. Preparing to Code</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/cTn11RFZ7BQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video5 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>5. Building the Home Page</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/dP2ylh30YsE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video6 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>6. Testing in Android Studio</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/hop1RA6m9TE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video7 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>7. Writing Functions</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/yKliJL9xFTk" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video8 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>8. Backend Setup</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/PzFFjuN7Ai0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video9 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>9. Authentication</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/lbt49sunOKw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video10 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>10. Building the List Page</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/FoqEhnwGo_8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video11 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>11. Database Setup</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/EPjCTqLc6ug" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video12 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>12. Finishing the App</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/GlEXyg75DVo" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video13 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>13. Deploying to the Web</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/ndgtOT7iwsA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video14 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>14. Deploying to Google's Play Store</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/C7EFvrksufA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  video15 = (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "12px", marginTop: "18px" }}>
        <strong>15. Deploying to Apple's App Store</strong>
      </div>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/ND2EuAsOBGc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please
      send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc">zach@codingtutor.nyc</a>.<br />
    </div>
  );

  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "44pt" }}>JavaScript</h1>
          <h2 style={{ fontSize: "17pt", paddingTop: "5px" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">
            Building an iOS, Android, & Web App Using React / Ionic / Firebase
          </div>
          {this.seriesIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.video1}
            {this.video2}
            {this.video3}
            {this.video4}
            {this.video5}
            {this.video6}
            {this.video7}
            {this.video8}
            {this.video9}
            {this.video10}
            {this.video11}
            {this.video12}
            {this.video13}
            {this.video14}
            {this.video15}
          </div>

          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "110px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "66pt" }}>JavaScript</h1>
          <h2 style={{ fontSize: "20pt" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">
            Building an iOS, Android, & Web App<br/>Using React/Ionic/Firebase
          </div>
          {this.seriesIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
          {this.video1}
            {this.video2}
            {this.video3}
            {this.video4}
            {this.video5}
            {this.video6}
            {this.video7}
            {this.video8}
            {this.video9}
            {this.video10}
            {this.video11}
            {this.video12}
            {this.video13}
            {this.video14}
            {this.video15}
          </div>

          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "120px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default JavaScript;
