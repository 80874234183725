// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import logo from "../images/logoDark.png";

class Animation extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Animation Tutor";
  };

  openingParagraph = (
    <div>
      <span>Children have loved animation for decades.  However, only very recently has technology allowed them to easily create their own cartoons.  When using a great animation app such as <strong>Flipaclip</strong>, children's creative potential is limitless, especially with guidance from a good teacher.
      </span>
      {/* <div style={{ height: "10px" }} />
      <span>animation</span> */}
    </div>
  );

  bumpIntro = (
    <div>
      <span>In this video, the 4th grade animator/narrator artfully explains the difference between life's big and small problems.</span>
    </div>
  );

  bumpVideo = (
    <div style={{ textAlign: "center" }}>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/FOFmUagCYwY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  yellowIntro = (
    <div>
      <span>Here's a beautiful video about an indestructable yellow balloon that never gives up!  It was made by five children, ages eight through ten.</span>
    </div>
  );

  yellowVideo = (
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/9NqGBN7lhN8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  );

  greekIntro = (
    <div>
      <span>Animation can be used to enhance any and all subject matter.  Below, two 5th graders tell a Greek myth that they invented as part of a unit about Greek mythology.</span>
    </div>
  );

  greekVideo = (
    <iframe width="100%" height="315" src="https://www.youtube.com/embed/kA4zo0kYjkU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  );


  

  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please
      send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc">zach@codingtutor.nyc</a>.<br />
    </div>
  );

  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "42pt" }}>Animation</h1>
          <h2 style={{ fontSize: "17pt", paddingTop: "5px" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">The Yellow Balloon</div>
          {this.yellowIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.yellowVideo}
          </div>

          <div className="subHeadingMobileScratch">Big Bump, Little Bump</div>
          {this.bumpIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.bumpVideo}
          </div>

          <div className="subHeadingMobileScratch">Faux Greek Myth</div>
          {this.greekIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.greekVideo}
          </div>

          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "110px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "58pt" }}>Animation</h1>
          <h2 style={{ fontSize: "20pt" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">The Yellow Balloon</div>
          {this.yellowIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.yellowVideo}
          </div>

          <div className="subHeadingDesktopScratch">Big Bump, Little Bump</div>
          {this.bumpIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.bumpVideo}
          </div>

          <div className="subHeadingDesktopScratch">Faux Greek Myth</div>
          {this.greekIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.greekVideo}
          </div>


          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "120px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default Animation;
