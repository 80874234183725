// import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { IonContent, IonRouterLink } from "@ionic/react";
import React, { Component } from "react";
import logo from "../images/logoDark.png";

class Claymation extends Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    document.title = "Claymation Tutor";
  };

  openingParagraph = (
    <div>
      <span>Creating a claymation movie is a labor of love.  It requires dedication and patience.  But the end result is absolutely magical!  And the possibilities are endless.  Claymation movies are also great mixed-age projects.  Almost anyone can contribute a character, a backdrop, a voice over, or a creative flourish.</span>
      <div style={{ height: "10px" }} />
      <span>Stop Motion Studio for the iPad is the best app for filming claymation movies.  Below are a few movies that I've made with my students.</span>
    </div>
  );

  p1Intro = (
    <div>
      <span>A strange "space boat" washes onto a beach where four children are playing.  What happens next will astound you!</span>
    </div>
  );

  p1Video = (
    <div style={{ textAlign: "center" }}>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/OFpxlPmpC2U" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  p2Intro = (
    <div>
      <span>After surviving Plantopia, the kids are back home.  But are they safe?</span>
    </div>
  );

  p2Video = (
    <div style={{ textAlign: "center" }}>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/2it6OqE4_k0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );

  eruptionIntro = (
    <div>
      <span>"Perhaps you should not play next to the volcano."</span>
    </div>
  );

  eruptionVideo = (
    <div style={{ textAlign: "center" }}>
      <iframe width="100%" height="315" src="https://www.youtube.com/embed/5CZ3SPdH9qM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );


  contact = (
    <div>
      To learn more about my tutoring services, or to schedule a lesson, please
      send an email to{" "}
      <a href="mailto:zach@codingtutor.nyc">zach@codingtutor.nyc</a>.<br />
    </div>
  );

  render() {
    let header: any;
    let main: any;

    ///////////////// MOBILE RENDER /////////////////
    if (this.props.appState.size == "mobile") {
      header = (
        <header className="cell headerMobile">
          <h1 style={{ fontSize: "34pt" }}>Claymation</h1>
          <h2 style={{ fontSize: "17pt", paddingTop: "5px" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainMobile">
          {this.openingParagraph}

          <div className="subHeadingMobileScratch">Escape from Plantopia</div>
          {this.p1Intro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.p1Video}
          </div>

          <div className="subHeadingMobileScratch">Escape from Plantopia II</div>
          {this.p2Intro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.p2Video}
          </div>

          <div className="subHeadingMobileScratch">The Eruption</div>
          {this.eruptionIntro}

          <div style={{ width: "100%", margin: "auto", marginTop: "18px" }}>
            {this.eruptionVideo}
          </div>

          <div className="subHeadingMobileScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "110px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    ///////////////// DESKTOP RENDER /////////////////
    if (this.props.appState.size == "desktop") {
      header = (
        <header className="cell headerDesktop">
          <h1 style={{ fontSize: "50pt" }}>Claymation</h1>
          <h2 style={{ fontSize: "20pt" }}>
            <IonRouterLink href="/">NYC Coding & Tech Tutor</IonRouterLink>
          </h2>
        </header>
      );

      main = (
        <main className="cell mainDesktop">
          {this.openingParagraph}

          <div className="subHeadingDesktopScratch">Escape from Plantopia</div>
          {this.p1Intro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.p1Video}
          </div>

          <div className="subHeadingDesktopScratch">Escape from Plantopia II</div>
          {this.p2Intro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.p2Video}
          </div>

          <div className="subHeadingDesktopScratch">The Eruption</div>
          {this.eruptionIntro}

          <div style={{ width: "70%", margin: "auto", marginTop: "18px" }}>
            {this.eruptionVideo}
          </div>

          <div className="subHeadingDesktopScratch">Contact</div>
          {this.contact}

          <div
            style={{
              textAlign: "center",
              marginTop: "28px",
              marginBottom: "14px",
            }}
          >
            <IonRouterLink href="/" style={{ textDecoration: "none" }}>
              <img style={{ width: "120px" }} src={logo} />
              <br />
              <span
                style={{
                  fontSize: "12pt",
                  letterSpacing: "1.4px",
                  textDecoration: "none",
                }}
              >
                &copy; CodingTutor.nyc
              </span>
            </IonRouterLink>
          </div>
        </main>
      );
    }

    return (
      <IonContent color="secondary">
        <section>
          {header}
          {main}
        </section>
      </IonContent>
    );
  }
}

export default Claymation;
